const state = {
  defaultNotbookId: "general",
  notebooks: [],
  activeNotebook: {},
  notes: [],
  activeNote: {},
  user: "",
  profileId: "",
  username: "",
  email: "",
  profileImage: "",
  provider: "",
  emailVerified: false
};

export default state;
